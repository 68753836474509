import React from 'react';

const windowWidth = window.innerWidth; // Use window.innerWidth for the window width in a web-based React app
const serverURL = 'https://server.coperinno.com';

const PostInUserProfile = ({ item }) => {
  var fromLeft = true;
  if (item.column === 'right') {
    fromLeft = false;
  }
  var baseWidth = windowWidth;
  if (windowWidth > 1000) {
    baseWidth = 1000;
  }
  var height = (baseWidth * 0.5 - 15) * (item.height / item.width);
  var marginRight = fromLeft ? 5 : 10;
  var marginLeft = fromLeft ? 10 : 5;
  var postURI = serverURL + '/image/' + item.image_path;
  
  return (
    <div
      style={{
        width: baseWidth * 0.5 - 15,
        marginLeft: marginLeft,
        marginRight: marginRight,
        marginTop: 10,
        backgroundColor: 'white',
      }}
    >
      <img
        style={{
          width: baseWidth * 0.5 - 15,
          height: height,
          borderWidth: '0.3px', // Use 'px' for specifying CSS pixel values
          borderRadius: '10px', // Use 'px' for specifying CSS pixel values
        }}
        src={postURI ? postURI : null}
        alt={item.post_title} // Use the alt attribute for image description
      />
      <div style={{ margin: '3px' }}> {/* Use 'px' for specifying CSS pixel values */}
        <h3
          style={{
            fontFamily: 'sans-serif', // You may need to include the OpenSans font
            textAlign: 'center',
          }}
        >
          {item.post_title}
        </h3>
      </div>
    </div>
  );
};

export default PostInUserProfile;
