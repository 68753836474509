import React from "react";
import { Helmet } from 'react-helmet';
import { websiteURL } from '../utils/consts';
import { redirectToApp } from '../utils/utils';


import image1 from '../images/1.jpg';
import image2 from '../images/2.jpg';
import image3 from '../images/3.jpg';

const MainPage  = () => {
    return (
        <div style={styles.landing}>
          <Helmet>
            <title>Coperinno - Blogging Simply. Share, Read, Explore.</title>
            <meta name="description" content="Install Coperinno - A simple, fun & creative way to write and share your stories with friends & family. Fun and easy blogging." />
            <link rel="canonical" href={websiteURL} />

          </Helmet>
          <header style={{...styles.landingHeader, ...styles.landingParagraph}}>
            <h1 style={styles.landingHeader}>Share your</h1>
            <h1 style={styles.sublandingHeader}>life stories and toughts</h1>
          </header>
          <div style={styles.ctaContainer}>
            <button 
              style={styles.ctaButton}
              onClick={() => redirectToApp()}>
              Open Coperinno
            </button>
          </div>
          <div style={styles.imageContainer}>
            <img src={image1} alt="Coperinno, the social blogging app" style={{height: window.innerWidth*0.32, margin: '1px'}} />
            <img src={image2} alt="Coperinno, the social blogging app" style={{height: window.innerWidth*0.32, margin: '1px'}} />
            <img src={image3} alt="Coperinno, the social blogging app" style={{height: window.innerWidth*0.32, margin: '1px'}} />
        </div>

        </div>
      );
    };
    

const styles = {
    landing: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: '10vh',
        height: '100vh',
        textAlign: 'center',
        color: '#333',
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginTop: '50px',
        width: '100%',
        },     
    landingHeader: {
        fontSize: '4rem',
        margin: '0.5rem 0',
    },
    sublandingHeader: {
      fontSize: '3rem',
      marginTop: 25,
      margin: '0.5rem 0',
      color: '#5b38ff',

  },

    landingParagraph: {
        fontSize: '1.5rem',
        color: 'black',
    },
    ctaContainer: {
        marginTop: '2rem',
    },
    ctaButton: {
        backgroundColor: '#5b38ff',
        color: 'white',
        border: 'none',
        padding: '10px 20px',
        fontSize: '1.3rem',
        width: '300px',
        borderRadius: '40px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    },
    ctaSpan: {
        display: 'block',
        marginTop: '10px',
        fontSize: '0.9rem',
        color: '#666',
    },
    };
export default MainPage
