import React from "react";
import { redirectToApp } from '../utils/utils';


const DeleteUserData  = () => {
    return (
        <div style={styles.landing}>
          <header style={{...styles.landingHeader, ...styles.landingParagraph}}>
            <p style={styles.landingParagraph}>
                
                Enter to User Page inside the App - Edit profile - Privacy & More - Delete Account :( 
                We are deleting the reference to your user and all the posts.
                We don't delete the following, saves and comments you have posted in the past</p>
          </header>
          <div style={styles.ctaContainer}>
            <button 
              style={styles.ctaButton}
              onClick={() => redirectToApp()}>
              Go to App
            </button>
          </div>
        </div>
      );
    };
    

const styles = {
    landing: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: '10vh',
        height: '100vh',
        textAlign: 'center',
        color: '#333',
    },      
    landingHeader: {
        fontSize: '3.5rem',
        margin: '0.5rem 0',
    },
    landingParagraph: {
        fontSize: '1.5rem',
        color: 'black',
    },
    ctaContainer: {
        marginTop: '2rem',
    },
    ctaButton: {
        backgroundColor: 'gray',
        color: 'white',
        border: 'none',
        padding: '10px 20px',
        fontSize: '1.2rem',
        cursor: 'pointer',
        borderRadius: '5px',
        transition: 'background-color 0.3s',
    },
};

export default DeleteUserData
