import React from 'react';
import './styles/globals.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainPage from "./componenets/MainPage";
import PrivacyPolicy from "./componenets/PrivacyPolicy";
import logoandname from './logoandname.jpeg'; 
import PostDetailsPage from './componenets/PostDetailsPage';
import UserDetailsPage from './componenets/UserDetailsPage';
import DeleteUserData from './componenets/DeleteUserData';
import ApplicationStoreRedirectPage from './componenets/ApplicationStoreRedirectPage';
import BlogPost1 from './componenets/BlogPost1';


const Home = () => (
        <div>
        <div className="footer">
            <a href={"/"}>
            <img style={{"height":"30px", "margin":"5px"}} src={logoandname} />
            </a>
        </div>
        <div className="coperinno-style">
        </div>
        <BrowserRouter>
        <Routes>
            <Route path="/" element={<MainPage/>}/>
            <Route path="/app" element={<ApplicationStoreRedirectPage/>}/>
            <Route path="/app/:uniqueRoute" element={<ApplicationStoreRedirectPage/>}/>
            <Route path="/app-privacy-policy" element={<PrivacyPolicy/>}/>
            <Route path="/posts/:postId" element={<PostDetailsPage/>} />
            <Route path="/user/:userName" element={<UserDetailsPage/>} />
            <Route path="/privacy/deleteuser" element={<DeleteUserData/>} />
            <Route path="/blog/blogging-on-mobile-start-blogging-today" element={<BlogPost1/>} />
        </Routes>
    </BrowserRouter>
    </div>
);

export default Home;
